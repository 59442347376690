.contact-form {
  position: relative;
  max-width: 700px;
  margin: 100px auto; /* Combined margin-top and margin-bottom */
  padding: 30px;
  border: 0px solid #ccc;
  background: url('../../Assets/vision.jpg') no-repeat center center;
  background-size: cover;
  font-family: "Poppins", sans-serif !important;
  font-weight: 200;
}

.contact-form::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  background: radial-gradient(ellipse farthest-corner at center center, rgba(0, 8, 22,0.7) 0%, rgba(0, 8, 22,1) 100%); /* White with 70% opacity */
  /* You can use any color and adjust opacity as needed */
  z-index: 1;
}

.contact-form > * {
  position: relative;
  z-index: 2; /* Ensures content is above the overlay */
}
  
  .contact-form h2 {
    margin-top: 0;
    text-align: center;
    color: white;
    position: relative;
    margin-bottom: 50px;
  }
   .contact-form h2::before{
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
    margin: auto;
    height: 3px;
    width:80px;
    
    background-color: var(--amber-color);
   
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    margin-bottom: 8px;
    font-weight: 400;
    color: white;
    float: left;
    
  }
  
  input[type='text'],
  input[type='email'],
  textarea {
    
      display: block;
      width: 100%;
      padding: .375rem .75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #212529;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border-radius: 0;
      transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    border:1px solid rgba(2, 15, 38, 0.1);
  }
  
  textarea {
    resize: none;
    height: 120px;
  }
  
  .contact-form button {
    padding: 12px 24px;
    background-color: #D6AD60;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .contact-form button:hover {
    background-color: #0b4f4f;
  }
  
  @media  (max-width:768px) {
    .contact-form {
     
      margin: 0px auto; /* Combined margin-top and margin-bottom */
      
    }
  }